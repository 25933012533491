@tailwind base;
@tailwind components;
@tailwind utilities;



body{
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.lds-ripple {
  display: inline-block;
  position: absolute;
  top: calc(50vh - 40px);
  left: calc(50vw - 40px);
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #b4540a;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Apply spin and zoom animations to the loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader svg {
  animation: spin 2s linear infinite, zoom 2s ease-in-out infinite;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 500ms ease-in-out;
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 500ms ease-in-out;
}


body {
  background-color: #ddd;
}

.social-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
}

/* Variable Declarations */
.social-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 64px;
  height: 64px;
  text-decoration: none;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}

.social-button::after {
  content: '';
  position: absolute;
  top: -1px;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  border-radius: 100%;
  transition: 0.3s;
}

.social-button:focus::after,
.social-button:hover::after {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  margin-left: calc(-50% - 1px);
}

.social-button i,
.social-button svg {
  position: relative;
  z-index: 1;
  transition: 0.3s;
}

.social-button i {
  font-size: 25.6px;
}

.social-button svg {
  height: 40%;
  width: 40%;
}

/* Dynamic Button Styles */
.social-button--linkedin {
  color: #0077b5;
}

.social-button--linkedin:hover {
  color: white;
}

.social-button--linkedin::after {
  background: #0077b5;
}

.social-button--github {
  color: #6e5494;
}

.social-button--github:hover {
  color: white;
}

.social-button--github::after {
  background: #6e5494;
}


#gotoAbout {
  font-size: 20pt;
  background-color: rgba(215, 215, 215, 0.2);
  border-radius: 100px;
  text-align: center;
  top: calc(100vh - 250px);
  left: calc(50vw - 67.33px/2);
  cursor: pointer;
  backdrop-filter: blur(2px);
  transition: background-color 500ms, backdrop-filter 500ms;
}

.menu-icon {
  position: relative;
  display: block;
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
}
.menu-icon:hover span {
    background-color: #eeee;
}
.menu-icon:hover span:before, .menu-icon:hover span:after {
    background-color: #eeee;
}

.menu-icon > span {
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;
  height: 0.175em;
  background-color: #ffff;
  border-radius: 3px;
  background-image: none;
  transition: transform 0.3s, background 0.25s ease;
}

.menu-icon > span:before,
.menu-icon > span:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffff;
  border-radius: 3px;
  background-image: none;
  transition: transform 0.3s, background 0.25s ease, opacity 0.25s;
}

.menu-icon > span:before {
  transform: translateY(-0.7em);
}

.menu-icon > span:after {
  transform: translateY(0.7em);
}

/* OPENED */
.menu-icon.open > span{
  transform: translateY(5px);
  background-color: #1f2937;
  
}

.menu-icon.open > span:before {
  transform: rotate(45deg);
  background-color: #ffff;
}

.menu-icon.open > span:after {
  transform: rotate(-45deg);
  background-color: #ffff;
}


.fade-in-top-menu { animation: fadeInTopMenu 500ms; }
@keyframes fadeInTopMenu {
  0%{
    height:72px;
  }
  100%{
    height: 100vh; /* For 100% screen height */
  }
}


.fade-out-top-menu { animation: fadeOutTopMenu 500ms; }
@keyframes fadeOutTopMenu {
  100%{
    height:72px;
    opacity:1;
  }
  0%{
    height: 100vh; /* For 100% screen height */
    opacity:1;
  }
}
